<template>
  <div class="d-flex flex-column justify-center h-100">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      overlay: true,
    };
  },
  created() {
    const rt = this.$route.query?.rt;
    if (rt) {
      // this.SET_OT_REF_TOKEN(rt);
    }
    setTimeout(() => {
      this.navigateReplace('MenuAll', { rt });
      this.overlay = false;
    }, 1000);
  },
  methods: {
    ...mapMutations(['SET_OT_REF_TOKEN']),
  },
};
</script>
<style lang="scss" scoped>
.referral-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
